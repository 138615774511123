header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99999;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0)
}

@media (max-width: 991px) {
    header.header {
        padding: 0;
    }
}

header.header .logo_sticky {
    display: none
}

header.header.sticky {
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid #ededed;
    background-color: #fff
}

@media (max-width: 991px) {
    header.header.sticky {
        padding: 0;
    }
}

header.header.sticky .logo_normal {
    display: none
}

header.header.sticky .logo_sticky {
    display: inline-block
}

header.header.sticky a.btn_top {
    background-color: #262626;
    color: #fff
}

header.header.sticky a.btn_top:hover {
    background-color: #111;
    color: #fff
}

header.header_in {
    padding: 15px 0;
    background-color: #fff;
    position: relative;
    border-bottom: 1px solid #ededed
}

header.header_in.is_sticky {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%
}

header.header_in ul#top_menu li a {
    color: #444
}

@media (max-width: 991px) {
    header.header_in {
        padding: 10px 0
    }

    header.header_in #logo a {
        z-index: 9;
        position: relative
    }
}

header a.open_close {
    position: absolute;
    left: 10px;
    top: 2px;
    z-index: 999;
    color: #fff;
    font-size: 32px;
    font-size: 2rem;
    display: none;
    width: 25px;
    height: 25px
}

@media (max-width: 991px) {
    header a.open_close {
        display: block
    }
}

header a.open_close span {
    display: none
}

header a.open_close:hover {
    opacity: 0.8
}

header.sticky a.open_close, .header_in a.open_close {
    color: #444
}

#logo {
    float: left;
    margin-left: 8px;
    margin-top: -23px;
    margin-bottom: -23px;
}

@media (max-width: 991px) {
    #logo {
        float: none;
        width: 100%;
        text-align: center;
        margin-top: -8px;
        margin-bottom: -8px;
    }

    #logo img {
        width: 70px;
        height: 70px;
    }
}

.main-menu {
    position: relative;
    z-index: 9;
    top: 4px;
    float: right;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.main-menu ul, .main-menu ul li {
    position: relative;
    margin: 0 8px;
    padding: 0
}

.main-menu ul a, .main-menu ul li a {
    position: relative;
    margin: 0;
    display: block;
    padding: 10px
}

.main-menu > ul > li > a {
    color: #fff;
    padding: 5px 8px;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500
}

.main-menu > ul > li:hover > a {
    color: #978667
}

header.sticky .main-menu > ul > li > a, header.header_in .main-menu > ul > li > a {
    color: #444
}

header.sticky .main-menu > ul > li:hover > a, header.header_in .main-menu > ul > li:hover > a {
    opacity: 1;
    color: #978667
}

#close_in, #header_menu {
    display: none
}

@media only screen and (min-width: 992px) {
    .main-menu {
        width: auto
    }

    .main-menu a {
        white-space: nowrap
    }

    .main-menu ul li {
        display: inline-block
    }

    .main-menu ul li.submenu:hover > a:before, .main-menu ul li.submenu:hover > a:after {
        bottom: -10px;
        opacity: 0
    }

    .main-menu ul ul {
        position: absolute;
        border-top: 2px solid #978667;
        z-index: 1;
        visibility: hidden;
        left: 3px;
        top: 110%;
        margin: 0;
        display: block;
        padding: 0;
        background: #fff;
        min-width: 210px;
        -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.175);
        -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.175);
        transform: scale(0.4);
        transform-origin: 10% top;
        transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px;
        opacity: 0;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease
    }

    .main-menu ul ul:before {
        bottom: 100%;
        left: 12%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: #978667;
        border-width: 7px;
        margin-left: -7px
    }

    .main-menu ul li:hover > ul {
        padding: 0;
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition-delay: 0.2s;
        transition-delay: 0.2s;
        -webkit-transition-duration: 0s, 0.2s, 0.2s;
        transition-duration: 0s, 0.2s, 0.2s
    }

    .main-menu ul ul li {
        display: block;
        height: auto;
        padding: 0
    }

    .main-menu ul ul li a {
        font-size: 13px;
        font-size: 0.8125rem;
        color: #444;
        border-bottom: 1px solid #ededed;
        display: block;
        padding: 12px 15px;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease
    }

    .main-menu ul ul li:last-child a {
        border-bottom: none
    }

    .main-menu ul ul li:first-child a:hover {
        -webkit-border-radius: 3px 3px 0 0;
        -moz-border-radius: 3px 3px 0 0;
        -ms-border-radius: 3px 3px 0 0;
        border-radius: 3px 3px 0 0
    }

    .main-menu ul ul li:last-child a {
        border-bottom: none
    }

    .main-menu ul ul li:last-child a:hover {
        -webkit-border-radius: 0 0 3px 3px;
        -moz-border-radius: 0 0 3px 3px;
        -ms-border-radius: 0 0 3px 3px;
        border-radius: 0 0 3px 3px
    }

    .main-menu ul ul li:hover > a {
        background-color: #f9f9f9;
        color: #978667;
        padding-left: 18px
    }

    .main-menu ul ul li.third-level > a {
        font-weight: normal !important
    }

    .main-menu ul ul li.third-level > a:hover {
        background-color: #f9f9f9;
        color: #978667;
        padding-left: 18px;
        opacity: 1
    }

    .main-menu ul ul li.third-level > a:after {
        font-family: 'ElegantIcons';
        content: "\35";
        float: right;
        font-size: 16px;
        font-size: 1rem;
        margin-top: -2px
    }

    .main-menu ul ul ul {
        position: absolute;
        border-top: 0;
        z-index: 1;
        height: auto;
        left: 100%;
        top: 0;
        margin: 0;
        padding: 0;
        background: #fff;
        min-width: 190px;
        -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.175);
        -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.175)
    }

    .main-menu ul ul ul:before {
        border-width: 0;
        margin-left: 0
    }
}

@media only screen and (max-width: 991px) {
    #header_menu {
        display: block;
        text-align: center;
        position: relative;
        padding: 30px 0 25px 0;
        background-color: #978667
    }

    #header_menu a.open_close {
        position: absolute;
        right: 15px;
        top: 0;
        left: inherit;
        color: #fff !important
    }

    .main-menu ul li a:hover, a.show-submenu:hover {
        color: #978667 !important;
        background-color: #f9f9f9;
        opacity: 1 !important
    }

    .main-menu ul li {
        border-top: none;
        border-bottom: 1px solid #ededed;
        color: #fff
    }

    .main-menu ul li a {
        padding: 15px !important
    }

    .main-menu li, .main-menu a {
        display: block;
        color: #333 !important
    }

    .main-menu li {
        position: relative
    }

    .main-menu ul > li {
        padding-bottom: 0
    }

    .main-menu ul > li i {
        float: right
    }

    .main-menu > ul > li.submenu:hover > a:after {
        transform: rotate(180deg);
        color: #978667
    }

    .main-menu ul > li.submenu > a:after {
        font-family: 'ElegantIcons';
        font-size: 21px;
        font-size: 1.3125rem;
        content: "\33";
        color: #444;
        line-height: 1;
        float: right;
        display: inline-block;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out
    }

    .main-menu ul li.submenu ul {
        border-left: 1px solid #ededed;
        margin: 0 0 10px 25px
    }

    .main-menu ul li.submenu ul li {
        border: 0;
        font-size: 13px;
        font-size: 0.8125rem
    }

    .main-menu ul li.submenu ul ul {
        border-left: none;
        margin: 0 0 0 15px
    }

    .main-menu {
        overflow-y: scroll;
        transform: translateX(-102%);
        top: 0;
        left: 0;
        bottom: 0;
        width: 55%;
        height: 100%;
        position: fixed;
        z-index: 999;
        background-color: #fff;
        -webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        -ms-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        -o-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1)
    }

    .main-menu.show {
        transform: translateX(0)
    }

    .main-menu .show-submenu + ul {
        display: none;
        visibility: hidden
    }

    .main-menu a.show-submenu + ul.show_normal {
        display: block;
        visibility: visible
    }
}

@media only screen and (max-width: 480px) {
    .main-menu {
        width: 100%
    }
}

a.btn_top {
    border: 1px solid #999;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding-left: 12px !important;
    padding-right: 12px !important
}

a.btn_top:hover {
    border-color: #978667;
    color: #fff !important;
    background-color: #978667
}

a.btn_top.order {
    border: 1px solid rgba(255, 255, 255, 0);
    background-color: #978667;
    color: #fff !important
}

a.btn_top.order:hover {
    background-color: #262626
}

@media (max-width: 991px) {
    a.btn_top {
        background-color: #262626;
        color: #fff !important;
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0
    }
}

.sticky a.btn_top {
    border: 1px solid rgba(0, 0, 0, 0.3)
}

.sticky a.btn_top.order {
    border: 1px solid rgba(255, 255, 255, 0);
    background-color: #978667;
    color: #fff !important
}

.sticky a.btn_top.order:hover {
    background-color: #262626
}

ul#top_menu {
    float: right;
    margin: 0 25px 0 25px;
    padding: 0;
    list-style: none;
    font-size: 13px;
    font-size: 0.8125rem
}

@media (max-width: 991px) {
    ul#top_menu {
        position: absolute;
        top: 12px;
        right: 15px;
        float: none;
        margin: -2px 0 0 10px
    }
}

ul#top_menu li {
    float: left;
    margin: 0;
    line-height: 1
}

ul#top_menu li a {
    color: #fff
}

ul#top_menu li a:hover {
    color: #fff;
    opacity: 0.7
}

ul#top_menu li a.cart_bt, ul#top_menu li a.search-overlay-menu-btn {
    display: block;
    width: 22px;
    height: 23px;
    position: absolute;
    top: 7px;
    -moz-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    -webkit-transition: opacity 0.5s ease;
    -ms-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease
}

ul#top_menu li a.cart_bt.cart_bt, ul#top_menu li a.search-overlay-menu-btn.cart_bt {
    top: 5px
}

@media (max-width: 991px) {
    ul#top_menu li a.cart_bt.cart_bt, ul#top_menu li a.search-overlay-menu-btn.cart_bt {
        top: 2px
    }
}

@media (max-width: 991px) {
    ul#top_menu li a.cart_bt, ul#top_menu li a.search-overlay-menu-btn {
        top: 2px;
        right: 5px
    }
}

ul#top_menu li a.cart_bt strong, ul#top_menu li a.search-overlay-menu-btn strong {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 700;
    width: 16px;
    height: 16px;
    text-indent: 0;
    display: block;
    text-align: center;
    position: absolute;
    bottom: -4px;
    right: -3px;
    line-height: 17px !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    background-color: #262626;
    color: #fff !important
}

@media (max-width: 767px) {
    ul#top_menu li a.cart_bt strong, ul#top_menu li a.search-overlay-menu-btn strong {
        bottom: -3px
    }
}

ul#top_menu li a.cart_bt:before, ul#top_menu li a.search-overlay-menu-btn:before {
    font-family: 'ElegantIcons';
    content: "\e013";
    font-size: 21px;
    font-size: 1.3125rem;
    text-indent: 0;
    position: absolute;
    left: 0;
    top: 0;
    font-weight: normal;
    line-height: 1
}

.sticky ul#top_menu li a.cart_bt:before, .sticky ul#top_menu li a.search-overlay-menu-btn:before {
    color: #444
}

ul#top_menu li a.search-overlay-menu-btn {
    position: relative;
    margin-right: 10px
}

@media (max-width: 991px) {
    ul#top_menu li a.search-overlay-menu-btn {
        top: 3px;
        right: 25px
    }
}

ul#top_menu li a.search-overlay-menu-btn:before {
    font-family: 'ElegantIcons';
    content: "\55"
}

.sticky ul#top_menu li a.search-overlay-menu-btn:before {
    color: #444
}

.dropdown-cart .dropdown-menu {
    margin-top: 11px !important;
    transform: translate(30px, 30px) !important;
    border: none;
    min-width: 0;
    padding: 15px;
    width: 260px;
    top: 100%;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.175);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.175);
    font-size: 13px;
    font-size: 0.8125rem;
    border-top: 2px solid #978667
}

.dropdown-cart .dropdown-menu:before {
    bottom: 100%;
    right: 5%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #978667;
    border-width: 7px;
    margin-left: -7px
}

@media (max-width: 767px) {
    .dropdown-cart .dropdown-menu {
        display: none !important
    }
}

.dropdown-cart .dropdown-menu .total_drop {
    font-size: 16px;
    font-size: 1rem
}

.dropdown-cart .dropdown-menu .total_drop strong {
    float: left
}

.dropdown-cart .dropdown-menu .total_drop span {
    float: right;
    font-weight: 600
}

.dropdown-cart .dropdown-menu .total_drop .btn_1 {
    margin-top: 5px;
    display: block
}

.dropdown-cart .dropdown-menu .total_drop .btn_1.outline {
    color: #333 !important
}

.dropdown-cart .dropdown-menu ul {
    padding: 0;
    list-style: none
}

.dropdown-cart .dropdown-menu ul li {
    border-bottom: 1px solid #ededed;
    margin: 0 0 10px 0 !important;
    padding: 0 15px 10px 60px;
    display: block;
    min-height: 60px;
    position: relative;
    float: none !important
}

.dropdown-cart .dropdown-menu ul li figure {
    width: 50px;
    height: 50px;
    overflow: hidden;
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    border: 1px solid #ededed
}

.dropdown-cart .dropdown-menu ul li figure img {
    width: auto;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -moz-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    -o-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1)
}

.dropdown-cart .dropdown-menu ul li a.action {
    color: #999;
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    height: 15px
}

.dropdown-cart .dropdown-menu ul li a.action i {
    font-size: 16px;
    font-weight: normal;
    color: #777
}

.dropdown-cart .dropdown-menu ul li a.action i:hover {
    color: #1a1a1a
}

.dropdown-cart .dropdown-menu ul li strong {
    color: #444;
    margin-top: 5px;
    display: inline-block;
    color: #333;
    font-weight: 600
}

.dropdown-cart .dropdown-menu ul li strong span {
    display: block;
    font-weight: normal;
    margin-bottom: 5px
}

.dropdown-cart .dropdown-menu a.btn_1 {
    height: auto;
    line-height: 1.4
}

.dropdown-cart .dropdown-menu a.btn_1.outline {
    background-color: transparent
}

.dropdown-cart .dropdown-menu a.btn_1.outline:hover {
    -webkit-filter: brightness(none !important);
    filter: brightness(none !important);
    color: #fff !important;
    background-color: #978667
}

.dropdown-cart .dropdown-menu a.btn_1:hover {
    color: #fff !important;
    background-color: #978667
}
