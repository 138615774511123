.swiggy-icon {
    height: 150px;
    width: 150px;
    margin-left: 5%;
    margin-bottom: 16px;
}

.zomato-icon {
    width: 200px;
    height: 150px;
    margin-right: -5%;
    margin-bottom: 16px;
}
