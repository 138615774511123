.tabs_menu_ul_wrapper {
    /* Make this scrollable when needed */
    overflow-x: auto;
    /* We don't want vertical scrolling */
    overflow-y: hidden;
    /* Make an auto-hiding scroller for the 3 people using a IE */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* For WebKit implementations, provide inertia scrolling */
    -webkit-overflow-scrolling: touch;
    /* We don't want internal inline elements to wrap */
    white-space: nowrap;
    /* Remove the default scrollbar for WebKit implementations */
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: #6c757d;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    position: relative;
    flex-direction: row;
}

.tabs_menu_ul_wrapper ul {
    list-style: none;
}

.arrow{
    position: sticky;
    position: -webkit-sticky; /* Safari */
    z-index: 100;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #ffffff;
    min-width: 26px;
    min-height: 26px;
    height: 26px;
    width: 26px;
    border: 1px solid #919191;
    border-radius: 13px;
}
.nav-prev {
    left: 0;
}
.nav-next {
    right: 0;
}
