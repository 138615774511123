.branch-name {
    cursor: pointer;
    flex: 25%;

}

.timings {
    flex: 20%;
}

.timings-separator {
    padding-left: 8px;
    padding-right: 8px;
}

.swiggy-small-icon {
    height: 75px;
    width: 75px;
}

.zomato-small-icon {
    height: 75px;
    width: 100px;
}
